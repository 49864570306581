@import "ngx-toastr/toastr";
@import "assets/fontawesome/css/all.min.css";
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&family=Ubuntu:wght@400;500;700&display=swap";

/* Importing Bootstrap SCSS file. */
@import "node_modules/@cloud-and-service/portal-lib/assets/css/bootstrap.min.css";
@import "node_modules/@cloud-and-service/portal-lib/assets/css/nifty.min.css";
@import "node_modules/@cloud-and-service/portal-lib/assets/scss/zangdar.scss";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss";

typeahead-container.dropdown.dropdown-menu {
    opacity: 100 !important;
    margin-top: 0 !important;
    pointer-events: all;
    touch-action: auto;
    z-index: 1200;
}

.boxed-layout {
    //padding-bottom: 1rem;
    background-color: #464646 !important;
}

*::-webkit-scrollbar {
    width: 0.6rem !important;
}

*::-webkit-scrollbar-track {
    background-color: transparent !important;
}

*::-webkit-scrollbar-thumb {
    background: darkgray !important; //rgba(135, 139, 144, 0.5) !important;
    border-radius: 2rem !important;
    border: 2px solid transparent;
}

*::-webkit-scrollbar-thumb:hover {
    background: darkslategray !important; //(135, 139, 144, 0.5) !important;
    width: 0.6rem !important;
    border: 0;
}

.btn-block {
    display: block;
    width: 100%;
}

a:hover {
    cursor: pointer;
    color: blue !important;
}

.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

html {
    height: 100%;
}

body {
    height: 100%;
    min-height: 100%;
}

.my-box {
    height: 100%;
    min-height: 100%;
    margin: 0 auto;
}

.boxed-layout {
    max-width: unset !important;
}

.page-title {
    text-transform: none !important;
}
