:root {
    --fowiz-muted-color: rgba(var(--bs-headings-color-rgb), .5);
    --fowiz-primary-color: var(--bs-comp-active-bg);
    --fowiz-checkmark-bg: var(--bs-green);
    --fowiz-checkmark-color: #fff;
}

$step-progress-dot-size : .35rem;
$step-progress-dot-gap : .45rem;
$step-progress-border-width : 2px;


.zangdar__wizard .zangdar__step {
    display: none;
}

.zangdar__wizard .zangdar__step.zangdar__step__active {
    display: block;
}





// Checkmark
// ----------------------------------------------
.checkmark {
    width: 106px;
    height: 106px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: var(--fowiz-checkmark-color);
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px var(--fowiz-checkmark-bg);
    animation: checkmark-fill .4s ease-in-out .4s forwards, checkmark-check .3s ease-in-out .9s both;

    &__circle {
        stroke-dasharray: 216;
        stroke-dashoffset: 216;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: var(--fowiz-checkmark-bg);
        fill: none;
        animation: checkmark-stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    &__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 98;
        stroke-dashoffset: 98;
        animation: checkmark-stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
}


// Checkmark animation keyframes
@keyframes checkmark-stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes checkmark-check {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes checkmark-fill {
    100% {
        box-shadow: inset 0px 0px 0px 80px var(--fowiz-checkmark-bg);
    }
}





// Step progress
// ----------------------------------------------
.step-progress {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;

    li {
        &:first-child:after {
            left: calc(50% + #{ $step-progress-dot-gap });
        }

        &:last-child:after {
            display: none;
        }

        flex: 2;
        position: relative;
        padding: 0 0 1rem 0;
        font-size: var(--bs-body-font-size);
        white-space: nowrap;
        overflow: visible;
        min-width: 0;
        text-align: center;

        &:before {
            content: "";
            display: block;
            width: $step-progress-dot-size;
            height: $step-progress-dot-size;
            background-color: var(--fowiz-primary-color);
            border-radius: 50%;
            position: absolute;
            left: calc(50% - #{ calc($step-progress-dot-size / 2) });
            bottom: calc(-1px + #{ 0 - calc($step-progress-dot-size / 2) });
            z-index: 5;
        }

        &:after {
            content: "";
            display: block;
            width: calc(100% - #{ $step-progress-dot-gap * 2 });
            height: $step-progress-border-width;
            position: absolute;
            bottom: -2px;
            left: calc(50% + #{ $step-progress-dot-gap });
            z-index: 2;
            border-bottom: $step-progress-border-width solid var(--fowiz-primary-color);
            box-shadow: inset 0 0 0 $step-progress-border-width var(--fowiz-muted-color);
        }
    }

    .active {

        // Default border
        ~li:before {
            background-color: var(--fowiz-muted-color);
        }

        &:after,
        ~li:after {
            border-bottom: 0;
        }


        // Active border
        &:before {
            background-color: transparent;
            box-shadow: 0 0 0 $step-progress-border-width var(--fowiz-primary-color);
        }
    }

    // Label
    .step-label {
        font-size: 95%;
        color: var(--fowiz-muted-color);
    }

    // Active label
    .active .step-label {
        font-size: .9rem;
        font-weight: 600;
        color: var(--fowiz-primary-color);
    }
}