/* -- ROOT -- */
/* ---------------------------------------------- */
.root {
  display: grid;
  gap: 0 0;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  min-height: 100vh;
  width: 100%;
}

body {
  overflow-x: hidden;
  position: relative;
  background-size: cover;
  background-attachment: fixed;
}

:root {
  --nf-backdrop-bg: rgba(26, 29, 33, 0.75);
  --nf-content-title-size: 2.25rem;
  --nf-content-title-color: var(--bs-primary);
  --nf-content-title-weight: 500;
}

/* -- REBOOT -- */
/* ---------------------------------------------- */
/* -- Scrollbar -- */
/* ---------------------------------------------- */
/* UDKOMMENTERET DA DE ØDELGGER SF SCROLL BARE I SCHEDULEREN */
/* @supports (-moz-appearance: none) {
  .scrollable-content {
    scrollbar-color: rgba(0, 0, 0, 0.25) transparent;
    scrollbar-width: auto;
  }
}
html[data-overlayscrollbars] > body::-webkit-scrollbar {
  width: 0 !important;
  visibility: hidden;
}

::-webkit-scrollbar {
  width: 0.35rem;
  height: 0.35rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  width: 2rem;
  background: rgba(0, 0, 0, 0.5);
}  */
/* UDKOMMENTERET DA DE ØDELGGER SF SCROLL BARE I SCHEDULEREN
@supports (-moz-appearance: none) {
  [data-bs-theme=dark] .scrollable-content {
    scrollbar-color: rgba(255, 255, 255, 0.25) transparent;
  }
}
[data-bs-theme=dark] ::-webkit-scrollbar-track {
  background-color: transparent;
}
[data-bs-theme=dark] ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.25);
}
[data-bs-theme=dark] ::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.5);
}

/* -- FRONT CONTAINER -- */
/* ---------------------------------------------- */
.front-container {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
}

.front-container .header {
  grid-area: 1/1/2/2;
}

.front-container .content {
  background-color: transparent;
  color: inherit;
  grid-area: 2/1/3/2;
}

.front-container .footer {
  grid-area: 3/1/4/2;
}

body[style*=background]:not(.boxed-layout)>.front-container,
.bg-img:not(.boxed-layout)>.front-container {
  background-color: rgba(var(--bs-body-bg-rgb), 0.5);
  background-attachment: fixed;
  background-blend-mode: screen;
}

/* -- CONTENTS -- */
/* ---------------------------------------------- */
.root:not(.hd--expanded) .content__header>.content__wrap {
  padding-bottom: 0 !important;
}

.content {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  display: flex;
  flex-direction: column;
  grid-area: 2/2/3/3;
  width: 100%;
  min-width: 0;
  max-width: 100vw;
}

.content .page-title {
  color: var(--nf-content-title-color);
  font-size: var(--nf-content-title-size);
  font-weight: var(--nf-content-title-weight);
  text-transform: capitalize;
}

.content__wrap {
  padding: 1rem 1rem;
}

@media (min-width: 768px) {
  .content__wrap {
    padding-inline: 1.5rem;
  }
}

@media (min-width: 992px) {
  .content__wrap {
    padding-inline: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .content__wrap {
    padding-inline: 1.25rem;
  }
}

@media (min-width: 1400px) {
  .content__wrap {
    padding-inline: 2.5rem;
  }
}

/* -- CONTENT VIEW -- */
/* ---------------------------------------------- */
.content-full-page {
  position: fixed;
  inset: 0;
  z-index: 999999;
  border-radius: 0 !important;
}

/* -- SINGLE CONTENT VIEW -- */
/* ---------------------------------------------- */
.body-sc>.root {
  position: relative;
  height: 100vh;
  overflow: hidden;
  z-index: 999999;
}

.body-sc .sidebar,
.body-sc .header,
.body-sc .content__header:after {
  z-index: 0 !important;
}

.single-content {
  display: flex;
  min-height: 0 !important;
  flex: 1 1 0;
}

@media (min-width: 576px) {
  .single-content-sm {
    display: flex;
    min-height: 0 !important;
    flex: 1 1 0;
  }
}

@media (min-width: 768px) {
  .single-content-md {
    display: flex;
    min-height: 0 !important;
    flex: 1 1 0;
  }
}

@media (min-width: 992px) {
  .single-content-lg {
    display: flex;
    min-height: 0 !important;
    flex: 1 1 0;
  }
}

@media (min-width: 1200px) {
  .single-content-xl {
    display: flex;
    min-height: 0 !important;
    flex: 1 1 0;
  }
}

@media (min-width: 1400px) {
  .single-content-xxl {
    display: flex;
    min-height: 0 !important;
    flex: 1 1 0;
  }
}

/* -- SEARCHBOX -- */
/* ---------------------------------------------- */
.searchbox {
  --nf-search-focus-bg: rgba(0, 0, 0, 0.15);
  --nf-search-collapsed-width: 20vw;
  --nf-search-expanded-width: 35vw;
  --nf-search-transition-duration: 0.35s;
  --nf-search-transition-delay: 0.15s;
  --nf-search-transition-timing: ease;
  width: auto;
}

.searchbox .searchbox__input {
  padding-right: calc(1ex + 2rem);
  transition: width var(--nf-search-transition-duration) var(--nf-search-transition-timing) var(--nf-search-transition-delay), background-color var(--nf-search-transition-duration) var(--nf-search-transition-timing) var(--nf-search-transition-delay);
}

.searchbox .searchbox__input:focus {
  transition: width var(--nf-search-transition-duration) var(--nf-search-transition-timing) var(--nf-search-transition-delay), background-color var(--nf-search-transition-duration) var(--nf-search-transition-timing) var(--nf-search-transition-delay);
}

.searchbox .searchbox__input.bg-transparent {
  background-color: transparent !important;
  border: 0;
  box-shadow: none !important;
  color: inherit;
}

.searchbox .searchbox__input.bg-transparent:focus {
  background-color: var(--nf-search-focus-bg) !important;
}

.searchbox.input-group:not(.has-validation)>.searchbox__input:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-radius: var(--bs-border-radius);
}

.searchbox .searchbox__btn {
  font-size: 1rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity var(--nf-search-transition-duration) var(--nf-search-transition-timing) var(--nf-search-transition-delay), visibility var(--nf-search-transition-duration) var(--nf-search-transition-timing) var(--nf-search-transition-delay);
  z-index: 5;
}

.searchbox.searchbox--auto-expand .searchbox__input {
  width: var(--nf-search-collapsed-width);
}

.searchbox.searchbox--auto-expand .searchbox__input:focus {
  width: var(--nf-search-expanded-width);
}

.searchbox.searchbox--hide-btn .searchbox__btn {
  opacity: 0;
  visibility: hidden;
}

.searchbox.searchbox--hide-btn .searchbox__input:focus~.searchbox__btn,
.searchbox.searchbox--hide-btn .searchbox__input:focus~div>.searchbox__btn {
  opacity: 1;
  transition: opacity var(--nf-search-transition-duration) var(--nf-search-transition-timing), visibility var(--nf-search-transition-duration) var(--nf-search-transition-timing);
  visibility: visible;
}

/* -- SCROLL PAGE TO TOP -- */
/* ---------------------------------------------- */
.scroll-container {
  --nf-btn-scroll-bg: var(--bs-primary);
  --nf-btn-scroll-color: var(--bs-primary-color);
  --nf-btn-scroll-border: 3px solid var(--bs-body-bg);
  --nf-btn-scroll-border-radius: 50%;
  --nf-btn-scroll-size: 4em;
  --nf-btn-scroll-right-margin: 1rem;
  --nf-btn-scroll-bottom-margin: 1rem;
  min-height: var(--nf-btn-scroll-size);
  pointer-events: none;
  position: absolute;
  right: var(--nf-btn-scroll-right-margin);
  top: min(100% - var(--nf-btn-scroll-size), 120vh);
  bottom: var(--nf-btn-scroll-size);
  clip-path: inset(0 0 0 0);
  z-index: 999;
}

.scroll-page {
  align-items: center;
  background-color: var(--nf-btn-scroll-bg);
  border: var(--nf-btn-scroll-border);
  border-radius: var(--nf-btn-scroll-border-radius);
  color: var(--nf-btn-scroll-color) !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  pointer-events: visible;
  position: sticky;
  right: var(--nf-btn-scroll-right-margin);
  text-decoration: none;
  top: calc(100vh - var(--nf-btn-scroll-size) - var(--nf-btn-scroll-bottom-margin));
  width: var(--nf-btn-scroll-size);
  z-index: 99999;
}

.scroll-page:after {
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: inline-block;
  height: 30%;
  left: 35%;
  pointer-events: none;
  position: absolute;
  top: 43%;
  transform-origin: center center;
  transform: rotate(-45deg);
  width: 30%;
}

/* -- BACKDROP FOR MAIN NAVIGATION AND SIDEBAR -- */
/* ---------------------------------------------- */
.root:before {
  background-color: var(--nf-backdrop-bg);
  content: "";
  display: block;
  inset: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  z-index: -1;
}

.root.mn--show:before,
.root.sb--show:before {
  cursor: pointer;
  opacity: 1;
  pointer-events: visible;
  visibility: visible;
  z-index: 9998;
}

/* -- TIMELINE -- */
/* ---------------------------------------------- */
.timeline {
  --nf-timeline-line-color: var(--bs-primary);
  --nf-timeline-line-style: solid;
  --nf-timeline-line-width: 2px;
  --nf-timeline-point-color: var(--bs-primary);
  --nf-timeline-point-gap: 0.25rem;
  --nf-timeline-point-size: 0.5rem;
  --nf-timeline-point-media-size: 2.25rem;
  --nf-timeline-entry-gap-x: 0.75rem;
  --nf-timeline-entry-gap-y: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: var(--nf-timeline-entry-gap-y);
}

.timeline .tl-time:not(:empty) {
  min-width: 7rem;
  text-align: end;
}

.timeline .tl-time .tl-date {
  font-size: 90%;
}

.timeline .tl-time .tl-time {
  color: var(--bs-heading-color);
  font-weight: 700;
}

.timeline .tl-entry {
  display: flex;
  gap: var(--nf-timeline-entry-gap-x);
}

.timeline .tl-point:before {
  background-color: var(--nf-timeline-point-color);
  border-radius: 50%;
  content: "";
  display: block;
  height: var(--nf-timeline-point-size);
  transform: translateY(0.75ex);
  width: var(--nf-timeline-point-size);
}

.timeline .tl-point:after {
  border-inline-start: var(--nf-timeline-line-width) var(--nf-timeline-line-style) var(--nf-timeline-line-color);
  content: "";
  display: block;
  height: calc(100% - var(--nf-timeline-point-size) - var(--nf-timeline-point-gap) * 2 + var(--nf-timeline-entry-gap-y));
  inset: calc(0.75ex + var(--nf-timeline-point-gap)) auto auto calc(50% - var(--nf-timeline-line-width) / 2);
  position: relative;
}

.timeline .tl-media {
  flex: 0 0 var(--nf-timeline-point-media-size);
}

.timeline .tl-media i {
  align-items: center;
  display: flex;
  justify-content: center;
}

.timeline .tl-media:after {
  border-inline-start: var(--nf-timeline-line-width) var(--nf-timeline-line-style) var(--nf-timeline-line-color);
  content: "";
  display: block;
  height: calc(100% - var(--nf-timeline-point-media-size) - var(--nf-timeline-point-gap) * 2 + var(--nf-timeline-entry-gap-y));
  inset: var(--nf-timeline-point-gap) auto auto calc(50% - var(--nf-timeline-line-width) / 2);
  position: relative;
}

.timeline .tl-entry.active .tl-icon .ratio {
  box-shadow: 0 0 0 var(--nf-timeline-line-width), 0 0 0 calc(var(--nf-timeline-line-width) * 2) var(--nf-timeline-line-color);
}

.timeline .tl-entry.active .tl-point:before {
  background-color: transparent;
  box-shadow: 0 0 0 var(--nf-timeline-line-width) var(--nf-timeline-point-color);
}

.timeline .tl-entry.active .tl-point:after {
  height: calc(100% - var(--nf-timeline-line-width) - var(--nf-timeline-point-size) - var(--nf-timeline-point-gap) * 2 + var(--nf-timeline-entry-gap-y));
  top: calc(0.75ex + var(--nf-timeline-point-gap) + var(--nf-timeline-line-width));
}

.timeline .tl-entry:last-child .tl-point:after,
.timeline .tl-entry:last-child .tl-media:after {
  border-color: var(--bs-secondary);
  border-inline-start-style: dotted;
}

.timeline-two-column .tl-entry>.tl-time,
.timeline-two-column .tl-content {
  width: 50%;
}

.timeline-two-column .tl-content>* {
  display: inline-flex;
  flex-direction: column;
}

.timeline-two-column .tl-entry-start {
  flex-direction: row-reverse;
}

.timeline-two-column .tl-entry-start .tl-time {
  text-align: start;
}

.timeline-two-column .tl-entry-start .tl-content {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  justify-content: end;
}

/* -- SPEECH BUBBLE -- */
/* ---------------------------------------------- */
.bubble {
  --nf-bubble-border-radius: 1.5rem;
  --nf-bubble-max-width: min(400px, 80%);
  --nf-bubble-primary-bg: var(--bs-primary);
  --nf-bubble-primary-color: var(--bs-primary-color);
  --nf-bubble-secondary-bg: var(--bs-white);
  --nf-bubble-secondary-color: var(--bs-dark);
  --nf-bubble-padding-x: 1rem;
  --nf-bubble-padding-y: 0.75rem;
  background-color: var(--nf-bubble-secondary-bg);
  border-radius: var(--nf-bubble-border-radius);
  border-end-start-radius: 0;
  color: var(--nf-bubble-secondary-color);
  display: inline-flex;
  flex-direction: column;
  max-width: var(--nf-bubble-max-width);
  padding: var(--nf-bubble-padding-y) var(--nf-bubble-padding-x);
  position: relative;
}

.bubble:after {
  border-color: transparent transparent var(--nf-bubble-secondary-bg) transparent;
  border-style: solid;
  border-width: 0 0 5px 7px;
  bottom: 0;
  content: "";
  display: block;
  left: -7px;
  position: absolute;
  width: 0;
  z-index: 1;
}

.bubble.bubble-primary,
.bubble-primary .bubble {
  background-color: var(--nf-bubble-primary-bg);
  border-radius: var(--nf-bubble-border-radius);
  border-end-end-radius: 0;
  color: var(--nf-bubble-primary-color);
}

.bubble.bubble-primary:after,
.bubble-primary .bubble:after {
  border-color: transparent transparent transparent var(--nf-bubble-primary-bg);
  border-width: 5px 0 0 7px;
  left: auto;
  right: -7px;
}

/* -- HEADER -- */
/* ---------------------------------------------- */
.root {
  --nf-header-bg: var(--bs-component-bg);
  --nf-header-color: var(--bs-component-color);
  --nf-header-secondary-color: var(--bs-secondary-color);
  --nf-header-border-color: var(--bs-border-color);
  --nf-header-height: 3.125rem;
  --nf-header-shadow: var(--bs-box-shadow-sm);
  --nf-header-btn-hover-bg: rgba(var(--bs-body-color-rgb), 0.1);
  --nf-header-btn-hover-color: var(--bs-heading-color);
  --nf-header-btn-border-radius: calc(var(--bs-border-radius) * 2);
  --nf-header-transition-duration: 0.35s;
  --nf-header-transition-timing: ease;
}

.header {
  background-color: var(--nf-header-bg);
  box-shadow: var(--nf-header-shadow);
  color: var(--nf-header-color);
  grid-area: 1/1/2/3;
}

.header__inner {
  align-items: center;
  display: flex;
  height: var(--nf-header-height);
  padding-inline: 1rem;
}

.root {
  --nf-brand-bg: transparent;
  --nf-brand-color: var(--nf-header-color);
  --nf-brand-size: 16px;
  --nf-brand-img-margin: 0.475rem 0.5rem;
  --nf-brand-font-size: 1.25rem;
  --nf-brand-font-weight: 500;
  --nf-brand-font-family: Ubuntu, sans-serif;
}

.header__brand {
  align-items: center;
  background-color: var(--nf-brand-bg);
  color: var(--nf-brand-color);
  display: flex;
  height: 100%;
  margin-inline-end: 0.5rem;
}

.brand-wrap {
  align-items: center;
  display: flex;
  position: relative;
}

.brand-wrap:hover .brand-title {
  box-shadow: 0 4px 0 -2px;
}

.brand-img {
  text-align: center;
  width: auto;
}

.brand-img img {
  width: var(--nf-brand-size);
  height: var(--nf-brand-size);
}

.brand-title {
  display: none;
}

.header__content {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: space-between;
}

.header__content-start {
  flex-grow: 1;
}

.header__content-start,
.header__content-end {
  align-items: center;
  display: flex;
  gap: 0.3rem;
}

.header__content-start>*,
.header__content-end>* {
  margin: 0 -0.1rem;
}

.header__content-start>.vr,
.header__content-end>.vr {
  background-color: color-mix(in srgb, currentColor 50%, transparent);
}

.header__btn {
  -webkit-appearance: none;
  appearance: none;
  border-radius: var(--nf-header-btn-border-radius);
  border-color: transparent !important;
  box-shadow: none !important;
  color: inherit;
  font-size: 0.8rem;
}

.header__btn.show,
.header__btn:hover,
.header__btn:focus,
.header__btn:active {
  background-color: var(--nf-header-btn-hover-bg);
  color: var(--nf-header-btn-hover-color);
}

.header-searchbox {
  position: relative;
  margin-left: auto;
}

.header-searchbox .searchbox__input::placeholder {
  color: var(--nf-header-secondary-color);
}

.searchbox__backdrop {
  position: relative;
}

.hd--sticky .header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.hd--sticky.mn--sticky.mn--max .mainnav .mainnav__inner {
  height: calc(100vh - var(--nf-header-height));
  top: var(--nf-header-height);
}

@media (max-width: 767.98px) {
  .header__content .searchbox {
    background-color: var(--nf-header-bg);
    left: 0;
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9999;
  }

  .header__content .searchbox .searchbox__input {
    position: fixed;
    top: -99rem;
  }

  .header__content .searchbox .searchbox__input,
  .header__content .searchbox .searchbox__input:focus~.searchbox__backdrop {
    margin: 1rem 0;
  }

  .header__content .searchbox .searchbox__input,
  .header__content .searchbox .searchbox__btn {
    transition-duration: 0s;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    .header__content .searchbox .searchbox__input {
      font-size: 16px;
    }
  }

  .header__content .searchbox .searchbox__input:focus {
    background-color: var(--nf-header-btn-hover-bg) !important;
    position: static;
  }

  .header__content .searchbox .searchbox__input:focus~.searchbox__backdrop:after {
    background-color: var(--nf-backdrop-bg);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: calc(3.1rem + calc(var(--bs-body-line-height) * var(--bs-body-font-size)));
  }

  .header__content .dropdown-menu {
    width: 100vw;
  }

  .hd--sticky .header__content .dropdown-menu {
    max-height: calc(100vh - (var(--nf-header-height)));
    overflow-x: hidden;
  }
}

@media (min-width: 768px) {

  .brand-img,
  .header-searchbox {
    margin: 0;
  }

  .brand-title {
    color: var(--nf-brand-color) !important;
    display: block;
    font-family: var(--nf-brand-font-family);
    font-size: var(--nf-brand-font-size);
    font-weight: var(--nf-brand-font-weight);
    line-height: var(--nf-brand-font-size);
    margin-inline: var(--nf-brand-img-margin);
    text-decoration: none !important;
  }

  .header .searchbox .searchbox__input.bg-transparent {
    border-radius: calc(var(--bs-border-radius) * 2) !important;
  }

  .header .searchbox .searchbox__input.bg-transparent:focus {
    background-color: var(--nf-header-btn-hover-bg) !important;
  }

  .searchbox__backdrop {
    height: 100%;
    margin: 0 !important;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media (min-width: 992px) {
  .mn--max .header__brand {
    transition: max-width var(--nf-header-transition-duration) var(--nf-header-transition-timing);
    width: 100%;
  }
}

@media (min-width: 768px) {
  .header__inner {
    padding-inline: 1.5rem;
  }
}

@media (min-width: 992px) {
  .header__inner {
    padding-inline: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .header__inner {
    padding-inline: 1.25rem;
  }
}

@media (min-width: 1400px) {
  .header__inner {
    padding-inline: 2.5rem;
  }
}

@media (min-width: 992px) {

  .mn--min .header__brand,
  .mn--max .header__brand {
    margin-inline-end: 0;
  }
}

/* -- MAINNAV MENU LINK -- */
/* ---------------------------------------------- */
.mainnav__caption {
  color: var(--nf-mainnav-heading-color);
  padding-inline: calc(calc(var(--nf-mainnav-submenu-indent) - var(--nf-mainnav-submenu-dashed-margin)) + var(--nf-mainnav-link-padding-x));
}

.mainnav__inner .list-group-item,
.mainnav__inner .px-m {
  padding-inline: calc(calc(var(--nf-mainnav-submenu-indent) - var(--nf-mainnav-submenu-dashed-margin)) + var(--nf-mainnav-link-padding-x));
}

.root .mainnav__inner .mainnav__menu .nav-link:not(.active):not(:hover):not(:active) .nav-label:where(.fw-medium, .fw-semibold, .fw-bold, .fw-bolder),
.mn--min .mainnav__inner .mainnav__menu .nav-link .nav-label:where(.fw-medium, .fw-semibold, .fw-bold, .fw-bolder) {
  color: var(--nf-mainnav-heading-color);
}

.mainnav__inner .mainnav__menu .nav-link {
  padding: var(--nf-mainnav-link-padding-y) var(--nf-mainnav-link-padding-x);
}

.mainnav__inner .mainnav__menu>.nav-item>.nav-link {
  display: flex;
}

.mainnav__inner .mainnav__menu>.nav-item>.nav-link.active {
  background-color: var(--nf-mainnav-submenu-active-bg);
  border-radius: var(--nf-mainnav-submenu-border-radius);
  box-shadow: var(--nf-mainnav-submenu-active-shadow);
  color: var(--nf-mainnav-submenu-active-color);
  font-weight: 600;
}

.mainnav__inner .mainnav__menu>.nav-item>.nav-link.active i,
.mainnav__inner .mainnav__menu>.nav-item>.nav-link.active .nav-icon {
  color: inherit !important;
}

.root.mn--min .nav-item:not(.has-sub) .nav-link.active>.nav-label {
  color: var(--nf-mainnav-link-active);
  text-decoration: var(--nf-mainnav-link-active-underline) var(--nf-mainnav-link-active-underline-color);
  text-underline-offset: 5px;
}

.root.mn--min .nav-item:not(.has-sub) .nav-link.active>.nav-label:hover {
  color: var(--nf-mainnav-link-hover);
  text-decoration-color: currentColor;
}

.root .mainnav__inner .nav-link {
  color: var(--nf-mainnav-link-color);
}

.root .mainnav__inner .nav-link.disabled {
  opacity: 0.4;
}

.root .mainnav__inner .nav-link:hover {
  color: var(--nf-mainnav-link-hover);
}

.root .mainnav__inner .nav-link.active~.nav .active {
  color: var(--nf-mainnav-link-active);
  text-decoration: var(--nf-mainnav-link-active-underline) var(--nf-mainnav-link-active-underline-color);
  text-underline-offset: 5px;
}

.root .mainnav__inner .nav-link.active~.nav .active:hover {
  color: var(--nf-mainnav-link-hover);
  text-decoration-color: currentColor;
}

.root .mainnav__inner .nav-link i,
.root .mainnav__inner .nav-link .nav-icon {
  color: var(--nf-mainnav-icon-color) !important;
}

.mainnav__menu .mininav-content {
  flex-direction: column;
  flex-wrap: nowrap;
}

.mainnav__menu .mininav-toggle {
  align-items: center;
  display: flex;
}

.mainnav__menu .mininav-toggle>.nav-label,
.mainnav__menu .mininav-toggle .nav-icon,
.mainnav__menu .mininav-toggle i {
  pointer-events: none;
}

.mainnav__menu .mininav-toggle .nav-label {
  display: inline-block;
}

.mainnav__menu .mininav-toggle>.nav-label.mininav-content {
  height: auto !important;
}

.mainnav__menu .has-sub>.mininav-toggle:not(.has-badge):after {
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: "";
  display: inline-block;
  height: 0.55em;
  margin-left: auto;
  transform: rotate(135deg);
  transition: transform calc(var(--nf-mainnav-transition-duration) / 2);
  width: 0.55em;
}

.mainnav__menu .has-sub>.mininav-toggle.collapsed:after {
  transform: rotate(45deg);
}

.root:not(.mn--min) .mainnav__menu .has-sub:not(.opened) .mininav-toggle.active:not(.collapsed)+.mininav-content {
  display: block;
}

@media (max-width: 991.98px) {
  .root .mininav-content {
    position: static !important;
    transform: none !important;
  }

  .root .mainnav__menu>.nav-item.has-sub .has-sub>.mininav-content {
    border-inline-start: 1px dashed var(--nf-mainnav-submenu-dashed-color);
    border-radius: 0;
    margin-inline-start: calc(calc(var(--nf-mainnav-submenu-indent-lv2) - 0.5em) + var(--nf-mainnav-link-padding-x)) !important;
    padding-inline-start: 0.5rem;
  }

  .root .mainnav__menu>.nav-item.has-sub .has-sub>.mininav-content>.nav-item>.nav-link {
    padding-inline: 0.75em;
  }

  .root .mainnav__menu>.nav-item.has-sub>.mininav-content {
    border-inline-start: 1px dashed var(--nf-mainnav-submenu-dashed-color);
    margin-inline-start: var(--nf-mainnav-submenu-dashed-margin) !important;
  }

  .root .mainnav__menu>.nav-item.has-sub>.mininav-content>.nav-item>.nav-link {
    padding-inline-start: calc(calc(var(--nf-mainnav-submenu-indent) - var(--nf-mainnav-submenu-dashed-margin)) + var(--nf-mainnav-link-padding-x));
  }
}

@media (min-width: 992px) {
  .root:not(.mn--min) .mininav-content {
    position: static !important;
    transform: none !important;
  }

  .root:not(.mn--min) .mainnav__menu>.nav-item.has-sub .has-sub>.mininav-content {
    border-inline-start: 1px dashed var(--nf-mainnav-submenu-dashed-color);
    margin-inline-start: calc(0.25em + var(--nf-mainnav-link-padding-x)) !important;
    padding-inline-start: 0.5rem;
  }

  .root:not(.mn--min) .mainnav__menu>.nav-item.has-sub .has-sub>.mininav-content>.nav-item>.nav-link {
    padding-inline: 0.75em;
  }

  .root:not(.mn--min) .mainnav__menu>.nav-item.has-sub>.mininav-content {
    border-inline-start: 1px dashed var(--nf-mainnav-submenu-dashed-color);
    margin-inline-start: var(--nf-mainnav-submenu-dashed-margin) !important;
  }

  .root:not(.mn--min) .mainnav__menu>.nav-item.has-sub>.mininav-content>.nav-item>.nav-link {
    padding-inline-start: calc(calc(var(--nf-mainnav-submenu-indent) - var(--nf-mainnav-submenu-dashed-margin)) + var(--nf-mainnav-link-padding-x));
  }
}

/* -- MAIN NAVIGATION - MINI / COLLAPSED MODE -- */
/* ---------------------------------------------- */
@media (min-width: 992px) {
  .mn--min {
    --nf-mainnav-min-icon-color: var(--bs-primary-text-emphasis);
    --nf-mainnav-min-icon-active-color: var(--bs-primary-color);
    --nf-mainnav-min-submenu-padding-x: 0.5rem;
    --nf-mainnav-min-submenu-padding-y: 0.5rem;
    --nf-mainnav-min-submenu-active-bg: var(--bs-primary);
    --nf-mainnav-min-submenu-active-color: var(--bs-primary-color);
    --nf-mainnav-min-shadow: var(--bs-box-shadow-lg);
    --nf-mainnav-min-submenu-link-padding-x: 0.75rem;
    --nf-mainnav-min-submenu-link-padding-y: 0.7rem;
    padding: 0 !important;
  }

  .mn--min .mainnav {
    max-width: var(--nf-mainnav-min-width);
    transition: max-width var(--nf-mainnav-min-transition-duration) var(--nf-mainnav-min-transition-timing);
    z-index: 1000;
  }

  .mn--min .mainnav__inner .mainnav__menu>.nav-item>.nav-link {
    padding: 0.59rem;
  }

  .mn--min .mainnav__bottom-content,
  .mn--min .mainnav__top-content {
    padding-inline: calc((var(--nf-mainnav-min-width) - calc(var(--bs-body-font-size) + 1.5rem)) / 2);
  }

  .mn--min .header__inner {
    padding-left: 0;
  }

  .mn--min .header__brand {
    justify-content: center;
    max-width: var(--nf-mainnav-min-width);
    padding-inline-start: 0;
    transition: max-width var(--nf-mainnav-min-transition-duration) var(--nf-mainnav-min-transition-timing);
    width: 100%;
  }

  .mn--min.mn--sticky.hd--expanded .content__header::before {
    z-index: 100;
  }

  .mn--min.mn--sticky.hd--expanded .mainnav {
    z-index: 99;
  }

  .mn--min.hd--sticky .mainnav__inner {
    top: var(--nf-header-height);
    z-index: auto;
  }

  .mn--sticky.mn--min.hd--sticky .mainnav__inner {
    height: calc(100vh - var(--nf-header-height));
  }

  .mn--min .mainnav__caption {
    display: block;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .mn--min .mainnav__widget .mininav-content {
    min-width: 13rem;
  }

  .mn--min .mainnav__widget .mininav-content .mainnav-widget-toggle[data-bs-toggle=collapse] {
    pointer-events: none;
  }

  .mn--min .mainnav__widget .mininav-content .nav {
    display: block !important;
  }

  .mn--min .mainnav__avatar {
    height: auto;
    width: 2rem;
  }

  .mn--min .mainnav__categoriy {
    border-bottom: 1px solid var(--nf-mainnav-border-color);
  }

  .mn--min .d-mn-min {
    display: block;
  }

  .mn--min .d-mn-max,
  .mn--min .d-mn-max:not(.show),
  .mn--min .brand-title,
  .mn--min .mainnav__menu>.has-sub>.nav-link:after,
  .mn--min .mainnav__menu>.nav-item>.nav-link .nav-label:not(.show) {
    display: none;
  }

  .mn--min .mainnav__menu>.nav-item>.nav-link {
    justify-content: center;
  }

  .mn--min .mainnav__menu>.nav-item>.nav-link i,
  .mn--min .mainnav__menu>.nav-item>.nav-link .nav-icon {
    color: var(--nf-mainnav-min-icon-color) !important;
  }

  .mn--min .mainnav__menu>.nav-item>.nav-link.active {
    background-color: var(--nf-mainnav-min-submenu-active-bg);
    color: var(--nf-mainnav-min-submenu-active-color);
  }

  .mn--min .mainnav__menu>.nav-item>.nav-link.active i,
  .mn--min .mainnav__menu>.nav-item>.nav-link.active .nav-icon {
    color: var(--nf-mainnav-min-icon-active-color) !important;
  }

  .mn--min .mainnav__menu>.nav-item,
  .mn--min .mainnav__menu>.nav-item>.nav-link i {
    margin: 0 !important;
  }

  .mn--min .mainnav__menu>.nav-item {
    margin-bottom: 0.25rem !important;
    width: 100%;
  }

  .mn--min .mainnav__menu>.has-sub .has-sub>.nav-link {
    gap: 2.5rem;
  }

  .mn--min .mininav-toggle {
    cursor: pointer;
  }

  .mn--min .mininav-toggle .nav-label {
    color: var(--nf-mainnav-color);
  }

  .mn--min .mininav-content {
    background-color: var(--nf-mainnav-bg);
    border-radius: var(--nf-mainnav-submenu-border-radius);
    box-shadow: var(--nf-mainnav-min-shadow);
    margin-inline-start: calc((var(--nf-mainnav-min-width) - calc(var(--bs-body-font-size) + 1.5rem)) / 2) !important;
    max-width: 19rem;
    min-width: 11rem;
    padding: var(--nf-mainnav-min-submenu-padding-y) var(--nf-mainnav-min-submenu-padding-x);
    white-space: nowrap;
  }

  .mn--min .mininav-content .nav-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: var(--nf-mainnav-min-submenu-link-padding-y) var(--nf-mainnav-min-submenu-link-padding-x);
  }

  .mn--min .mininav-content:not(.show) {
    display: none;
  }

  .mn--min .mininav-content.show {
    position: fixed;
    top: -100rem;
    z-index: 100;
  }

  .mn--min .mininav-content .arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 7px 0;
    border-color: transparent var(--nf-mainnav-bg) transparent transparent;
    left: -6px;
  }

  .mn--min .mininav-content:not(.nav) {
    padding: 0.75rem 0.25rem;
  }

  .mn--min .mininav-content.nav-label {
    padding: 0.75rem var(--bs-nav-link-padding-x);
  }

  .mn--min .mininav-content.collapsing {
    transition: 0s !important;
  }

  .mn--min .mainnav__top-content {
    flex: 1 1 0;
    min-height: 0 !important;
  }

  .mn--min .mainnav__bottom-content {
    z-index: 100;
  }
}

/* -- MAIN NAVIGATION - MAX / EXPANDED MODE -- */
/* ---------------------------------------------- */
@media (min-width: 992px) {
  .mn--max .mainnav {
    position: relative;
  }

  .mn--max .mainnav__inner {
    position: absolute;
  }

  .mn--max .mainnav {
    transition: max-width var(--nf-mainnav-max-transition-duration) var(--nf-mainnav-max-transition-timing);
    will-change: max-width;
  }

  .mn--max .mainnav__widget .mininav-content {
    height: max-content;
  }

  .mn--max .mainnav__caption {
    display: block;
    overflow: hidden;
    max-height: 3rem;
    transition: max-height var(--nf-mainnav-max-transition-duration) calc(var(--nf-mainnav-max-transition-duration) * 1.5);
  }

  .mn--max .header__inner {
    padding-inline-start: 0;
  }
}

@media (min-width: 992px) and (min-width: 992px) {

  .mn--max .header__content,
  .mn--min .header__content {
    top: 1.25rem;
    padding-inline-start: 1.25rem;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {

  .mn--max .header__content,
  .mn--min .header__content {
    top: 1.25rem;
    padding-inline-start: 1.25rem;
  }
}

@media (min-width: 992px) and (min-width: 1400px) {

  .mn--max .header__content,
  .mn--min .header__content {
    top: 2.5rem;
    padding-inline-start: 2.5rem;
  }
}

@media (min-width: 992px) {
  .mn--max .header__brand {
    padding-inline-start: calc(0.625rem + var(--nf-mainnav-link-padding-x));
    max-width: var(--nf-mainnav-max-width);
    width: var(--nf-mainnav-max-width);
    will-change: max-width;
  }
}

/* -- DEFAULT MAIN NAVIGATION -- */
/* ---------------------------------------------- */
.root {
  --nf-mainnav-bg: var(--bs-component-bg);
  --nf-mainnav-color: var(--bs-body-color);
  --nf-mainnav-secondary-color: var(--bs-secondary-color);
  --nf-mainnav-link-color: var(--bs-body-color);
  --nf-mainnav-link-hover: var(--bs-link-hover-color);
  --nf-mainnav-link-active: var(--bs-heading-color);
  --nf-mainnav-link-active-underline: underline dotted;
  --nf-mainnav-link-active-underline-color: rgba(var(--bs-body-color-rgb), 0.5);
  --nf-mainnav-link-padding-x: 1.25rem;
  --nf-mainnav-link-padding-y: 0.59rem;
  --nf-mainnav-icon-color: var(--bs-primary-text-emphasis);
  --nf-mainnav-heading-color: var(--bs-heading-color);
  --nf-mainnav-border-color: var(--bs-border-color);
  --nf-mainnav-padding: 0.75rem;
  --nf-mainnav-transition-duration: 0.35s;
  --nf-mainnav-transition-timing: ease;
  --nf-mainnav-submenu-active-bg: var(--bs-primary);
  --nf-mainnav-submenu-active-color: var(--bs-primary-color);
  --nf-mainnav-submenu-active-shadow: none;
  --nf-mainnav-submenu-indent: 2.25em;
  --nf-mainnav-submenu-indent-lv2: 0.75em;
  --nf-mainnav-submenu-border-radius: var(--bs-border-radius);
  --nf-mainnav-submenu-dashed-margin: 2.25em;
  --nf-mainnav-submenu-dashed-color: var(--bs-primary-border-subtle);
}

.mn--max,
.mn--min {
  --nf-mainnav-max-width: 13.75rem;
  --nf-mainnav-max-transition-duration: 0.35s;
  --nf-mainnav-max-transition-timing: ease;
  --nf-mainnav-min-width: 3.75rem;
  --nf-mainnav-min-transition-duration: 0.35s;
  --nf-mainnav-min-transition-timing: ease;
}

.mainnav {
  --bs-secondary-color: var(--nf-mainnav-secondary-color);
  display: flex;
  grid-area: 2/1/3/2;
  max-width: var(--nf-mainnav-max-width);
  width: min(75vw, var(--nf-mainnav-max-width));
}

.mainnav__inner {
  background-color: var(--nf-mainnav-bg);
  color: var(--nf-mainnav-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.mainnav__inner :where(h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6):not(.mainnav__caption) {
  color: var(--nf-mainnav-heading-color);
}

.mainnav__inner :where(.border-top, .border-end, .border-bottom, .border-start) {
  border-color: var(--nf-mainnav-border-color) !important;
}

.mainnav__inner .dropdown-toggle:after {
  color: var(--nf-mainnav-heading-color);
}

.d-mn-max,
.d-mn-max.collapse {
  display: block;
}

.d-mn-min {
  display: none;
}

.nav-label {
  white-space: nowrap;
}

.mainnav__avatar.border {
  padding: 0.1rem;
}

.mainnav__top-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.mainnav__top-content.os-host-overflow {
  overflow: visible;
}

.mainnav__bottom-content {
  background-color: var(--nf-mainnav-bg);
  bottom: 0;
  margin-top: auto;
  position: sticky;
}

.mainnav__top-content,
.mainnav__bottom-content {
  padding-inline: var(--nf-mainnav-padding);
}

.mn--show .mainnav {
  transform: translateX(0);
  z-index: 9999;
}

.mn--show .mainnav,
.mainnav {
  transition: transform var(--nf-mainnav-transition-duration) var(--nf-mainnav-transition-timing);
}

.mn--sticky .mainnav__inner {
  height: 100vh;
  position: sticky;
  top: 0;
}

.root:not(.mn--max):not(.mn--min) .mainnav {
  position: absolute;
  inset: 0 auto;
}

.root:not(.mn--max):not(.mn--min):not(.mn--show) .mainnav {
  z-index: 1002;
}

@media (max-width: 991.98px) {
  .mainnav__menu .mininav-toggle .nav-label {
    position: static !important;
    transform: none !important;
  }

  .mn--min .mainnav {
    inset: 0 auto;
    position: absolute;
  }

  .mn--min:not(.mn--show) .mainnav {
    transform: translateX(max(-75vw, calc(var(--nf-mainnav-max-width) * -1 - 0.0625rem)));
    z-index: 1002;
  }
}

@media (max-width: 991.98px) {
  .mn--max .mainnav {
    inset: 0 auto;
    position: absolute;
  }

  .mn--max:not(.mn--show) .mainnav {
    transform: translateX(calc(var(--nf-mainnav-max-width) * -1 - 0.0625rem));
    z-index: 1002;
  }
}

/* -- MAIN NAVIGATION - OFF CANVAS / SLIDE MODE -- */
/* ---------------------------------------------- */
.mn--slide {
  --nf-mainnav-slide-width: 13.75rem;
  --nf-mainnav-slide-transition-duration: 0.35s;
  --nf-mainnav-slide-transition-timing: ease;
}

.mn--slide .mainnav {
  max-width: 75vw;
  width: var(--nf-mainnav-slide-width);
  transform: translateX(max(-75vw, calc(var(--nf-mainnav-slide-width) * -1) - 0.0625rem));
}

.mn--slide .mainnav,
.mn--slide.mn--show .mainnav {
  transition: transform var(--nf-mainnav-slide-transition-duration) var(--nf-mainnav-slide-transition-timing);
}

.mn--slide.mn--show .mainnav {
  transform: translateX(0);
}

/* -- MAIN NAVIGATION - OFF CANVAS / PUSH MODE -- */
/* ---------------------------------------------- */
.mn--push {
  --nf-mainnav-push-width: 13.75rem;
  --nf-mainnav-push-transition-duration: 0.35s;
  --nf-mainnav-push-transition-timing: ease;
}

.mn--push .mainnav {
  max-width: 75vw;
  width: var(--nf-mainnav-push-width);
  transform: translateX(max(-75vw, calc(var(--nf-mainnav-push-width) * -1) - 0.0625rem));
}

.mn--push .mainnav,
.mn--push .content,
.mn--push .header,
.mn--push.mn--show .mainnav,
.mn--push.mn--show .content,
.mn--push.mn--show .header {
  transition: transform var(--nf-mainnav-push-transition-duration) var(--nf-mainnav-push-transition-timing);
}

.mn--push.mn--show .mainnav {
  transform: translateX(0);
  z-index: 9999;
}

.mn--push.mn--show .content,
.mn--push.mn--show .header {
  transform: translateX(min(75vw, var(--nf-mainnav-push-width)));
}

.mn--push.mn--show.sb--show.sb--pinned .sidebar {
  transform: translateX(min(75vw, var(--nf-mainnav-push-width)));
}

/* -- MAIN NAVIGATION - OFF CANVAS / REVEAL MODE -- */
/* ---------------------------------------------- */
.mn--reveal {
  --nf-mainnav-reveal-width: 13.75rem;
  --nf-mainnav-reveal-transition-duration: 0.35s;
  --nf-mainnav-reveal-transition-timing: ease;
}

.mn--reveal .mainnav {
  max-width: 75vw;
  pointer-events: none;
  transform: translateX(max(-75vw, calc(var(--nf-mainnav-reveal-width) * -1) - 0.0625rem));
  transform: translateX(0) !important;
  width: var(--nf-mainnav-reveal-width);
  z-index: 5 !important;
}

.mn--reveal .header {
  z-index: 12;
}

.mn--reveal .content,
.mn--reveal .sidebar {
  z-index: 10;
}

.mn--reveal.sb--pinned .sidebar {
  z-index: 11;
}

.mn--reveal .content,
.mn--reveal .header,
.mn--reveal.mn--show .content,
.mn--reveal.mn--show .header {
  transition: transform var(--nf-mainnav-reveal-transition-duration) var(--nf-mainnav-reveal-transition-timing);
  will-change: transform;
}

.mn--reveal.mn--show .mainnav {
  pointer-events: visible;
}

.mn--reveal.mn--show:before,
.mn--reveal.mn--show .content,
.mn--reveal.mn--show .header {
  transform: translateX(min(75vw, var(--nf-mainnav-reveal-width)));
}

.mn--reveal.mn--show:before {
  transition: opacity var(--nf-mainnav-reveal-transition-duration), var(--nf-mainnav-reveal-transition-duration) !important;
}

.mn--reveal.mn--show.sb--show.sb--pinned .sidebar {
  transform: translateX(min(75vw, var(--nf-mainnav-reveal-width)));
}

/* -- SIDEBARS -- */
/* ---------------------------------------------- */
.root {
  --nf-sidebar-bg: var(--bs-component-bg);
  --nf-sidebar-color: var(--bs-body-color);
  --nf-sidebar-width: 18rem;
  --nf-sidebar-shadow: var(--bs-box-shadow-lg);
  --nf-sidebar-padding-x: 0.75rem;
  --nf-sidebar-padding-y: 1rem;
  --nf-sidebar-transition-duration: 0.35s;
  --nf-sidebar-transition-timing: ease;
}

.sidebar {
  align-items: stretch;
  background-color: var(--nf-sidebar-bg);
  box-sizing: content-box;
  color: var(--nf-sidebar-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(var(--nf-sidebar-width));
  visibility: hidden;
  width: 0;
  z-index: 1002;
}

.sidebar .scrollable-content {
  scrollbar-width: none;
}

.sidebar__inner {
  height: 100vh;
  overflow-x: hidden;
  padding-block: var(--nf-sidebar-padding-y);
  position: sticky;
  top: 0;
}

.sidebar__wrap {
  padding-inline: var(--nf-sidebar-padding-x);
}

.sb--show .sidebar {
  overflow: visible;
  transform: translateX(0);
  visibility: visible;
  width: var(--nf-sidebar-width);
  z-index: 9999;
}

.sidebar {
  transition: visibility var(--nf-sidebar-transition-duration), width 0s linear var(--nf-sidebar-transition-duration), padding var(--nf-sidebar-transition-duration) var(--nf-sidebar-transition-timing), transform var(--nf-sidebar-transition-duration) var(--nf-sidebar-transition-timing);
}

.sb--show .sidebar {
  transition: visibility var(--nf-sidebar-transition-duration), padding var(--nf-sidebar-transition-duration) var(--nf-sidebar-transition-timing), transform var(--nf-sidebar-transition-duration) var(--nf-sidebar-transition-timing);
}

@media (min-width: 1195px) {
  .sb--pinned .sidebar {
    overflow: visible;
    transform: translateX(0);
    visibility: visible;
    width: var(--nf-sidebar-width);
    z-index: 9999;
  }

  .sidebar {
    transition: visibility var(--nf-sidebar-transition-duration), width 0s linear var(--nf-sidebar-transition-duration), padding var(--nf-sidebar-transition-duration) var(--nf-sidebar-transition-timing), transform var(--nf-sidebar-transition-duration) var(--nf-sidebar-transition-timing);
  }

  .sb--pinned .sidebar {
    transition: visibility var(--nf-sidebar-transition-duration), padding var(--nf-sidebar-transition-duration) var(--nf-sidebar-transition-timing), transform var(--nf-sidebar-transition-duration) var(--nf-sidebar-transition-timing);
  }
}

.sidebar__stuck {
  display: none;
}

/* -- DISABLE THE SIDEBAR BACKDROP -- */
/* ---------------------------------------------- */
.sb--bd-0.sb--show:before {
  opacity: 0;
}

.sb--bd-0.sb--show .sidebar {
  box-shadow: var(--nf-sidebar-shadow);
}

/* -- SIDEBAR - STATIC POSITION */
/* ---------------------------------------------- */
.sb--static .sidebar__inner {
  overflow: visible;
  position: static;
}

/* -- SIDEBAR - STUCK -- */
/* ---------------------------------------------- */
.sidebar__stuck {
  display: none;
}

.sb--stuck.sb--show:before {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.sb--stuck.sb--show:not(.sb--pinned) .sidebar {
  box-shadow: var(--nf-sidebar-shadow);
}

.sb--stuck .sidebar__stuck {
  display: flex;
}

/* -- SIDEBAR UNITE -- */
/* ---------------------------------------------- */
.root {
  --nf-sidebar-unite-bg: var(--nf-header-bg);
  --nf-sidebar-unite-color: var(--nf-header-color);
  --nf-sidebar-unite-secondary-color: var(--nf-header-secondary-color);
  --nf-sidebar-unite-active-bg: var(--nf-header-btn-hover-bg);
  --nf-sidebar-unite-active-color: var(--nf-header-btn-hover-color);
  --nf-sidebar-unite-border-color: var(--nf-header-border-color);
  --nf-sidebar-unite-outer-border-radius: calc(var(--bs-border-radius) * 1.5);
}

.sb--unite .sidebar {
  --bs-border-color: var(--nf-sidebar-unite-border-color);
  --bs-secondary-color: var(--nf-sidebar-unite-secondary-color);
  --bs-emphasis-color: var(--nf-sidebar-unite-active-color);
  background-color: var(--nf-sidebar-unite-bg);
  color: var(--nf-sidebar-unite-color);
}

.sb--unite .sidebar .nav-link.active,
.sb--unite .sidebar .nav-link:hover,
.sb--unite .sidebar .nav-link:focus,
.sb--unite .sidebar :where(h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6) {
  color: var(--nf-sidebar-unite-active-color);
}

.sb--unite .sidebar .btn-primary,
.sb--unite .sidebar .form-check-input:checked {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}

.sb--unite .sidebar .btn-link {
  color: inherit;
}

.sb--unite .sidebar .nav-link {
  color: var(--nf-sidebar-unite-color);
}

.sb--unite .sidebar .nav-link i,
.sb--unite .sidebar .nav-link .nav-icon {
  color: rgba(var(--nf-sidebar-unite-color), 0.65) !important;
}

.sb--unite .sidebar .list-group-item-action,
.sb--unite .sidebar .list-group-item {
  color: var(--nf-sidebar-unite-color);
}

.sb--unite .sidebar .list-group-item-action:hover,
.sb--unite .sidebar .list-group-item-action:focus {
  background-color: var(--nf-sidebar-unite-active-bg);
  color: var(--nf-sidebar-unite-active-color);
}

/* -- PINNED SIDEBAR */
/* ---------------------------------------------- */
@media (min-width: 1195px) {
  .sb--pinned:not(.mn--show):before {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .sb--pinned.hd--sticky .sidebar__inner {
    top: var(--nf-header-height);
  }

  .sb--pinned .content__boxed {
    padding-right: var(--nf-sidebar-width);
  }

  .sb--pinned:not(.mn--push):not(.mn--reveal) .sidebar {
    transition-duration: 0s !important;
  }

  .sb--pinned:not(.sb--unite) .sidebar {
    background-color: transparent;
  }

  .sb--pinned:not(.sb--unite) .sidebar .sidebar__inner {
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
  }

  .sb--pinned:not(.sb--static) .sidebar .sidebar__inner {
    height: calc(100vh - var(--nf-header-height));
    overflow-x: hidden;
  }

  .sb--pinned .sidebar {
    height: calc(100% - var(--nf-header-height));
    top: var(--nf-header-height);
    z-index: 999;
  }

  .sb--pinned .sidebar .sidebar__inner {
    border-top-left-radius: var(--nf-sidebar-unite-outer-border-radius);
  }

  .sb--pinned.root.mn--push .sidebar,
  .sb--pinned.root.mn--push.mn--show .sidebar {
    transition-duration: var(--nf-mainnav-push-transition-duration);
  }

  .sb--pinned.root.mn--push.mn--show .sidebar {
    transform: translateX(var(--nf-mainnav-push-width));
  }

  .sb--pinned.root.mn--reveal .sidebar,
  .sb--pinned.root.mn--reveal.mn--show .sidebar {
    transition-duration: var(--nf-mainnav-reveal-transition-duration);
  }

  .sb--pinned.root.mn--reveal.mn--show .sidebar {
    transform: translateX(var(--nf-mainnav-reveal-width));
  }

  .sb--pinned.sb--unite {
    --nf-header-shadow: none;
  }

  .sb--pinned.sb--unite .sidebar {
    content-visibility: visible;
  }

  .sb--pinned.sb--unite .sidebar__inner {
    border-radius: 0;
  }

  .sb--pinned.sb--unite.tm--primary-mn .header__content {
    box-shadow: none;
  }

  .sb--pinned.sb--unite .sidebar:after {
    background-color: var(--nf-sidebar-unite-bg);
    content: "";
    display: block;
    height: var(--nf-sidebar-unite-outer-border-radius);
    margin-left: calc(var(--nf-sidebar-unite-outer-border-radius) * -1);
    margin-top: -100vh;
    -webkit-mask-image: radial-gradient(circle at 0 var(--nf-sidebar-unite-outer-border-radius), transparent 0, transparent var(--nf-sidebar-unite-outer-border-radius), black var(--nf-sidebar-unite-outer-border-radius));
    mask-image: radial-gradient(circle at 0 var(--nf-sidebar-unite-outer-border-radius), transparent 0, transparent var(--nf-sidebar-unite-outer-border-radius), black var(--nf-sidebar-unite-outer-border-radius));
    position: relative;
    top: var(--nf-header-height);
    width: calc(var(--nf-sidebar-unite-outer-border-radius) + 0.06125rem);
  }

  .sb--pinned.sb--unite.hd--sticky .sidebar:after {
    position: sticky;
  }

  .sb--pinned.sb--unite.tm--tall-hd .content__header:after,
  .sb--pinned.sb--unite.tm--expanded-hd .content__header:after {
    align-self: flex-end;
    background-color: var(--nf-sidebar-unite-bg);
    content: "";
    display: block;
    height: calc(var(--nf-sidebar-unite-outer-border-radius) + 0.0625rem);
    -webkit-mask-image: radial-gradient(circle at 0 var(--nf-sidebar-unite-outer-border-radius), transparent var(--nf-sidebar-unite-outer-border-radius), black var(--nf-sidebar-unite-outer-border-radius));
    mask-image: radial-gradient(circle at 0 var(--nf-sidebar-unite-outer-border-radius), transparent var(--nf-sidebar-unite-outer-border-radius), black var(--nf-sidebar-unite-outer-border-radius));
    position: absolute;
    top: 100%;
    transition: transform 0.35s ease;
    width: calc(var(--nf-sidebar-unite-outer-border-radius) + 0.0625rem);
    z-index: 1000;
  }

  .sb--pinned.sb--unite.tm--tall-hd .content__header,
  .sb--pinned.sb--unite.tm--expanded-hd .content__header {
    --nf-header-expand-border-radius: 0;
  }
}

/* COLORED HEADER */
/* ---------------------------------------------- */
.tm--full-hd,
.tm--fair-hd,
.tm--tall-hd,
.tm--expanded-hd,
.header--primary {
  --nf-header-bg: var(--bs-primary);
  --nf-header-color: rgba(var(--bs-primary-color-rgb), 0.75);
  --nf-header-secondary-color: rgba(var(--bs-primary-color-rgb), 0.35);
  --nf-header-border-color: var(--bs-primary-border);
  --nf-header-shadow: var(--bs-box-shadow-sm);
  --nf-header-btn-hover-bg: rgba(0, 0, 0, 0.2);
  --nf-header-btn-hover-color: var(--bs-primary-color);
  --nf-brand-bg: initial;
  --nf-brand-color: var(--bs-primary-color);
}

/* COLORED BRAND */
/* ---------------------------------------------- */
.tm--primary-brand .header__brand,
.header-brand--primary {
  --nf-brand-bg: var(--bs-primary);
  --nf-brand-color: var(--bs-primary-color);
}

/* MAINNAV COLORED */
/* ---------------------------------------------- */
.tm--primary-mn,
.mainnav--primary {
  --nf-mainnav-bg: var(--bs-primary);
  --nf-mainnav-color: var(--bs-primary-color);
  --nf-mainnav-secondary-color: rgba(var(--bs-primary-color-rgb), 0.4);
  --nf-mainnav-link-color: rgba(var(--bs-primary-color-rgb), 0.7);
  --nf-mainnav-link-hover: var(--bs-primary-color);
  --nf-mainnav-link-active: var(--bs-primary-color);
  --nf-mainnav-link-active-underline-color: rgba(var(--bs-primary-color-rgb), 0.3);
  --nf-mainnav-icon-color: var(--bs-primary-color);
  --nf-mainnav-heading-color: var(--bs-primary-color);
  --nf-mainnav-border-color: var(--bs-primary-border);
  --nf-mainnav-submenu-active-bg: rgba(0, 0, 0, 0.25);
  --nf-mainnav-submenu-dashed-color: var(--bs-primary-border);
  --nf-mainnav-min-icon-color: var(--bs-primary-color);
  --nf-mainnav-min-icon-active-color: var(--bs-primary-color);
  --nf-mainnav-min-submenu-active-bg: rgba(0, 0, 0, 0.25);
  --nf-mainnav-min-submenu-active-color: var(--bs-primary-color);
  --nf-mainnav-min-shadow: 0 1rem 2rem rgba(26, 29, 33, 0.5);
}

/* -- EXPANDED HEADER -- */
/* ---------------------------------------------- */
.tm--tall-hd,
.tm--expanded-hd {
  --nf-header-shadow: none;
  --nf-header-expand-bg: var(--bs-primary);
  --nf-header-expand-color: rgba(var(--bs-primary-color-rgb), 0.65);
  --nf-header-expand-title-color: var(--bs-primary-color);
  --nf-header-expand-headings-color: var(--nf-header-expand-title-color);
  --nf-header-expand-height: 37.5vh;
  --nf-header-expand-border-radius: 0% 0% 50% 50%/0% 0% 12vh 12vh;
}

.tm--tall-hd .content__header,
.tm--expanded-hd .content__header {
  background-color: var(--nf-header-expand-bg);
  border-radius: var(--nf-header-expand-border-radius);
  color: var(--nf-header-expand-color);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.tm--tall-hd .content__header :where(h1, h2, h3, h4, h5, h6),
.tm--expanded-hd .content__header :where(h1, h2, h3, h4, h5, h6) {
  color: var(--nf-header-expand-headings-color);
}

.tm--tall-hd .content__header .text-body-emphasis:not(.btn),
.tm--expanded-hd .content__header .text-body-emphasis:not(.btn) {
  color: var(--nf-header-expand-headings-color) !important;
}

.tm--tall-hd .content__header .page-title,
.tm--expanded-hd .content__header .page-title {
  color: var(--nf-header-expand-title-color);
}

.tm--tall-hd .content__header .page-title:after,
.tm--expanded-hd .content__header .page-title:after {
  background-color: var(--nf-header-expand-title-color);
}

.tm--tall-hd .content__header .btn-link,
.tm--expanded-hd .content__header .btn-link {
  color: var(--nf-header-expand-title-color);
  opacity: 0.85;
}

.tm--tall-hd .content__header .breadcrumb-item:before,
.tm--expanded-hd .content__header .breadcrumb-item:before,
.tm--tall-hd .content__header .breadcrumb-item.active,
.tm--expanded-hd .content__header .breadcrumb-item.active {
  color: var(--nf-header-expand-title-color);
}

.tm--tall-hd .content__header .breadcrumb-item>a,
.tm--expanded-hd .content__header .breadcrumb-item>a {
  color: var(--nf-header-expand-color);
}

.tm--tall-hd .content__header .breadcrumb-item>a:hover,
.tm--expanded-hd .content__header .breadcrumb-item>a:hover,
.tm--tall-hd .content__header .breadcrumb-item>a:focus,
.tm--expanded-hd .content__header .breadcrumb-item>a:focus {
  color: var(--nf-header-expand-title-color);
}

.modal-open .tm--tall-hd .content__header+.content__boxed,
.modal-open .tm--expanded-hd .content__header+.content__boxed {
  position: relative;
  z-index: auto !important;
}

body[style*=padding-right] .tm--tall-hd .content__header+.content__boxed,
body[style*=padding-right] .tm--expanded-hd .content__header+.content__boxed {
  position: relative;
  z-index: auto !important;
}

.tm--tall-hd .content__header.overlapping .content__wrap:after,
.tm--expanded-hd .content__header.overlapping .content__wrap:after {
  content: "";
  display: block;
  height: var(--nf-header-expand-height);
  width: 100%;
}

.tm--tall-hd .content__header.overlapping+.content__boxed,
.tm--expanded-hd .content__header.overlapping+.content__boxed {
  margin-top: calc((var(--nf-header-expand-height) + 1rem) * -1);
  z-index: 99;
}

.tm--tall-hd .content__header:not(.overlapping):not(.rounded-0),
.tm--expanded-hd .content__header:not(.overlapping):not(.rounded-0) {
  min-height: 10.9375rem;
  padding-bottom: 10vh;
}

.mn--max.tm--expanded-hd.mn--sticky .content__header:before,
.mn--min.tm--expanded-hd.mn--sticky .content__header:before {
  position: sticky;
  top: -0.06125rem;
}

.mn--max.tm--expanded-hd.hd--sticky .content__header:not(.rounded-0):before,
.mn--max.tm--expanded-hd.mn--sticky .content__header:not(.rounded-0):before,
.mn--min.tm--expanded-hd.hd--sticky .content__header:not(.rounded-0):before,
.mn--min.tm--expanded-hd.mn--sticky .content__header:not(.rounded-0):before {
  margin-bottom: calc(var(--nf-sidebar-unite-outer-border-radius) * 2);
}

.mn--max.tm--expanded-hd.hd--sticky .content__header.overlapping:not(.rounded-0):before,
.mn--max.tm--expanded-hd.mn--sticky .content__header.overlapping:not(.rounded-0):before,
.mn--min.tm--expanded-hd.hd--sticky .content__header.overlapping:not(.rounded-0):before,
.mn--min.tm--expanded-hd.mn--sticky .content__header.overlapping:not(.rounded-0):before {
  margin-bottom: calc(var(--nf-sidebar-unite-outer-border-radius) + 12vh);
}

.mn--max.tm--expanded-hd.hd--sticky .content__header:before,
.mn--min.tm--expanded-hd.hd--sticky .content__header:before {
  position: sticky;
  top: var(--nf-header-height);
}

.mn--max.tm--expanded-hd.hd--sticky .content__header:not(.rounded-0) .content__wrap,
.mn--max.tm--expanded-hd.mn--sticky .content__header:not(.rounded-0) .content__wrap,
.mn--min.tm--expanded-hd.hd--sticky .content__header:not(.rounded-0) .content__wrap,
.mn--min.tm--expanded-hd.mn--sticky .content__header:not(.rounded-0) .content__wrap {
  margin-top: calc((var(--nf-sidebar-unite-outer-border-radius)) * 3 * -1);
}

.mn--max.tm--expanded-hd.hd--sticky .content__header.overlapping:not(.rounded-0) .content__wrap,
.mn--max.tm--expanded-hd.mn--sticky .content__header.overlapping:not(.rounded-0) .content__wrap,
.mn--min.tm--expanded-hd.hd--sticky .content__header.overlapping:not(.rounded-0) .content__wrap,
.mn--min.tm--expanded-hd.mn--sticky .content__header.overlapping:not(.rounded-0) .content__wrap {
  margin-top: calc((var(--nf-sidebar-unite-outer-border-radius) * 2 + 12vh) * -1);
}

.mn--max.tm--expanded-hd .content__header .content__wrap,
.mn--min.tm--expanded-hd .content__header .content__wrap {
  margin-top: calc(var(--nf-sidebar-unite-outer-border-radius) * -1);
}

.mn--max.tm--expanded-hd .content__header:before,
.mn--min.tm--expanded-hd .content__header:before {
  background-color: var(--bs-primary);
  content: "";
  display: block;
  height: var(--nf-sidebar-unite-outer-border-radius);
  margin-left: 0;
  -webkit-mask-image: radial-gradient(circle at 0 var(--nf-sidebar-unite-outer-border-radius), transparent 0, transparent var(--nf-sidebar-unite-outer-border-radius), black var(--nf-sidebar-unite-outer-border-radius));
  mask-image: radial-gradient(circle var(--nf-sidebar-unite-outer-border-radius) at 0 var(--nf-sidebar-unite-outer-border-radius), transparent 0, transparent var(--nf-sidebar-unite-outer-border-radius), black var(--nf-sidebar-unite-outer-border-radius));
  position: relative;
  top: -0.06125rem;
  transform: translateX(calc(var(--nf-sidebar-unite-outer-border-radius) * -1));
  width: calc(var(--nf-sidebar-unite-outer-border-radius) + 0.06125rem);
  z-index: 1001;
}

@supports (-webkit-touch-callout: none) {

  .mn--max.tm--expanded-hd .content__header:before,
  .mn--min.tm--expanded-hd .content__header:before {
    -webkit-mask-image: radial-gradient(circle at 0 var(--nf-sidebar-unite-outer-border-radius), transparent 0, transparent var(--nf-sidebar-unite-outer-border-radius), black var(--nf-sidebar-unite-outer-border-radius));
  }
}

/* -- FAIR HEADER -- */
/* ---------------------------------------------- */
.tm--fair-hd,
.tm--tall-hd {
  --nf-header-shadow: none;
}

.tm--fair-hd .header__brand,
.tm--tall-hd .header__brand {
  background-color: transparent;
  color: var(--nf-brand-color);
}

.tm--fair-hd .header__brand .brand-title,
.tm--tall-hd .header__brand .brand-title {
  color: inherit !important;
}

.tm--fair-hd.tm--expanded-hd .content__header:before,
.tm--expanded-hd.tm--tall-hd .content__header:before {
  visibility: hidden;
}

@media (min-width: 992px) {

  .tm--fair-hd.mn--min .header__brand,
  .mn--min.tm--tall-hd .header__brand,
  .tm--fair-hd.mn--max .header__brand,
  .mn--max.tm--tall-hd .header__brand {
    background-color: var(--nf-mainnav-bg);
    color: var(--nf-mainnav-link-active);
  }
}

/* -- CENTERED LAYOUT -- */
/* ---------------------------------------------- */
@media (min-width: 900px) {

  .centered-layout .tm--fair-hd .header__brand,
  .centered-layout .tm--tall-hd .header__brand {
    padding-inline: 1.25rem;
  }

  .centered-layout .tm--fair-hd.mn--min .header__inner,
  .centered-layout .mn--min.tm--tall-hd .header__inner,
  .centered-layout .tm--fair-hd.mn--max .header__inner,
  .centered-layout .mn--max.tm--tall-hd .header__inner {
    max-width: none !important;
  }

  .centered-layout .tm--fair-hd.mn--min .header__brand,
  .centered-layout .mn--min.tm--tall-hd .header__brand {
    width: 100%;
    max-width: var(--nf-mainnav-min-width);
    justify-content: center;
    padding: 0;
    margin-inline-end: 0 !important;
  }

  .centered-layout .tm--fair-hd.mn--min .header__brand .brand-title,
  .centered-layout .mn--min.tm--tall-hd .header__brand .brand-title {
    display: none;
  }

  .centered-layout .tm--fair-hd.mn--max .header__brand,
  .centered-layout .mn--max.tm--tall-hd .header__brand {
    width: var(--nf-mainnav-max-width);
  }
}

@media (min-width: 1120px) {

  .centered-layout .tm--fair-hd.mn--max .header__brand,
  .centered-layout .mn--max.tm--tall-hd .header__brand {
    padding-inline: 1.25rem;
  }
}

@media (min-width: 1200px) {

  .centered-layout .tm--fair-hd.mn--max .header__brand,
  .centered-layout .mn--max.tm--tall-hd .header__brand {
    padding-inline: 1.25rem;
  }
}

/* -- FULL HEADER -- */
/* ---------------------------------------------- */
.tm--full-hd {
  --bs-box-shadow-alpha-sm: .5;
  --nf-header-shadow: 0 0.125rem 0.25rem rgba(var(--bs-box-shadow-color), var(--bs-box-shadow-alpha-sm));
}

[data-bs-theme=dark] .tm--full-hd .header {
  --bs-box-shadow-alpha-sm: .1;
}

/* -- MAINNAV PRIMARY -- */
/* ---------------------------------------------- */
.tm--primary-mn {
  --nf-header-shadow: var(--bs-box-shadow-sm);
}

@media (min-width: 992px) {
  .tm--primary-mn:where(.mn--min, .mn--max) {
    --nf-brand-bg: var(--bs-primary);
    --nf-brand-color: var(--bs-primary-color);
  }

  .tm--primary-mn:where(.mn--min, .mn--max) .header__content {
    box-shadow: var(--nf-header-shadow);
  }
}

.tm--primary-mn.mn--reveal {
  background-color: black;
}

.tm--primary-mn.mn--reveal:before {
  outline: 1px solid var(--bs-primary);
}

.tm--primary-mn.mn--reveal .content {
  background-color: var(--bs-body-bg);
}

/* -- PRIMARY BRAND -- */
/* ---------------------------------------------- */
.tm--primary-brand {
  --nf-header-shadow: var(--bs-box-shadow-sm);
}

.tm--primary-brand .header__inner {
  padding-inline-start: 0;
}

.tm--primary-brand .header__brand {
  padding-inline: 1rem;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .tm--primary-brand .header__brand {
    padding-inline: 1.5rem;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .tm--primary-brand .tm--primary-brand:not(.mn--min):not(.mn--max) .header__brand {
    padding-inline: 1.25rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .tm--primary-brand .tm--primary-brand:not(.mn--min):not(.mn--max) .header__brand {
    padding-inline: 1.25rem;
  }
}

@media (min-width: 1400px) {
  .tm--primary-brand .tm--primary-brand:not(.mn--min):not(.mn--max) .header__brand {
    padding-inline: 2.5rem;
  }
}

@media (min-width: 992px) {

  .tm--primary-brand.mn--min .header__brand,
  .tm--primary-brand.mn--max .header__brand {
    border-radius: 0;
  }

  .tm--primary-brand.mn--max .header__brand {
    padding-inline: calc(0.625rem + var(--nf-mainnav-link-padding-x)) 0;
  }
}

/* -- TALL HEADER -- */
/* ---------------------------------------------- */
/* -- TRANSITION TIMING FUNCTIONS -- */
/* ---------------------------------------------- */
.in-quart.centered-layout .content__header:before,
.in-quart .mn--reveal.mn--show:before,
.in-quart :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.755, 0.045, 0.915, 0.135) !important;
}

.out-quart.centered-layout .content__header:before,
.out-quart .mn--reveal.mn--show:before,
.out-quart :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.015, 0.77, 0.04, 0.985) !important;
}

.in-back.centered-layout .content__header:before,
.in-back .mn--reveal.mn--show:before,
.in-back :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.705, -0.155, 0.735, 0.045) !important;
}

.out-back.centered-layout .content__header:before,
.out-back .mn--reveal.mn--show:before,
.out-back :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.28, 1.02, 0.36, 1.145) !important;
}

.in-out-back.centered-layout .content__header:before,
.in-out-back .mn--reveal.mn--show:before,
.in-out-back :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(1, -0.28, 0, 1.275) !important;
}

.steps.centered-layout .content__header:before,
.steps .mn--reveal.mn--show:before,
.steps :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0, 0.955, 1, 0.045) !important;
}

.jumping.centered-layout .content__header:before,
.jumping .mn--reveal.mn--show:before,
.jumping :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.96, 0.005, 0, 1.075) !important;
}

.rubber.centered-layout .content__header:before,
.rubber .mn--reveal.mn--show:before,
.rubber :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned .content__boxed) {
  transition-timing-function: cubic-bezier(0.135, 1.525, 0, 0.9) !important;
}

/* -- OPTIMIZE -- */
/* ---------------------------------------------- */
.offcanvas-body,
.dropdown-menu,
.sidebar {
  content-visibility: auto;
}

.sidebar img[loading=lazy],
.dropdown-menu img[loading=lazy],
.offcanvas img[loading=lazy] {
  display: none;
}

.sb--show .sidebar img[loading=lazy],
.dropdown-menu.show img[loading=lazy],
.offcanvas.show img[loading=lazy] {
  display: unset;
}

@media (min-width: 1195px) {
  .sb--pinned .sidebar img[loading=lazy] {
    display: unset;
  }
}

/* -- MEDIA PRINT -- */
/* ---------------------------------------------- */
@media print {

  html,
  body,
  #root,
  .content {
    background-color: transparent;
  }

  #root {
    background-color: #fff;
    color: #555e69;
    display: block;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: #30353b;
  }

  .content {
    color: inherit !important;
  }

  .badge,
  .btn {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .bg-light {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #ecf1f4 !important;
  }

  .text-muted,
  .text-body-secondary {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    color: #727e8c !important;
  }

  .table {
    border-color: #ecf1f4 !important;
    color: #30353b !important;
  }

  .table thead tr,
  .table thead th {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: transparent !important;
    color: #30353b !important;
  }

  .table tbody tr,
  .table tbody td {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    border-color: #ecf1f4;
    color: #30353b !important;
  }

  .table tbody td {
    background-color: transparent;
    box-shadow: none;
  }

  .table.table-striped tbody tr:nth-child(odd) td {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #ecf1f4;
  }

  .header,
  .sidebar,
  #mainnav-container,
  .content__header,
  footer,
  .scroll-container,
  #_dm-settingsToggler {
    display: none !important;
  }

  .content__header.overlapping+.content__boxed {
    margin-top: 0 !important;
  }

  .d-md-flex,
  .d-lg-flex,
  .d-xl-flex {
    display: flex !important;
  }
}

/* -- RIBBON -- */
/* ---------------------------------------------- */
.ribbon {
  height: 75px;
  overflow: hidden;
  position: absolute;
  right: -5px;
  text-align: right;
  top: -5px;
  width: 75px;
  z-index: 1;
}

.ribbon span {
  background: #e53f50;
  box-shadow: 0 3px 10px -3px rgba(var(--bs-box-shadow-color), 1);
  color: #fff;
  display: block;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  right: -21px;
  text-align: center;
  text-transform: uppercase;
  top: 19px;
  transform: rotate(45deg);
  width: 100px;
}

.ribbon span:before {
  border-bottom: 3px solid transparent;
  border-left: 3px solid #e53f50;
  border-right: 3px solid transparent;
  border-top: 3px solid #e53f50;
  content: "";
  left: 0px;
  position: absolute;
  top: 100%;
  z-index: -1;
}

.ribbon span:after {
  border-bottom: 3px solid transparent;
  border-left: 3px solid transparent;
  border-right: 3px solid #e53f50;
  border-top: 3px solid #e53f50;
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
}

/* -- ERROR CODE -- */
/* ---------------------------------------------- */
.error-code {
  font-size: 9rem !important;
  font-weight: 400;
  line-height: 1;
}

/* -- HOVER EFFECTS -- */
/* ---------------------------------------------- */
.hv-grow,
.hv-grow-parent .hv-gc,
.hv-grow:hover,
.hv-grow-parent:hover .hv-gc {
  transition: transform var(--nf-hv-transition-duration) var(--nf-hv-transition-timing);
}

.hv-outline:hover,
.hv-outline-parent:hover .hv-oc,
.hv-outline,
.hv-outline-parent .hv-oc {
  transition: outline var(--nf-hv-transition-duration) var(--nf-hv-transition-timing), outline-offset var(--nf-hv-transition-duration) var(--nf-hv-transition-timing), transform var(--nf-hv-transition-duration) var(--nf-hv-transition-timing);
}

.hv-grow:hover,
.hv-grow-parent:hover .hv-gc {
  backface-visibility: hidden;
  transform: scale(var(--nf-hv-grow-scale)) translateZ(0);
}

.hv-grow,
.hv-grow-parent,
.hv-outline,
.hv-outline-parent {
  --nf-hv-transition-duration: 0.35s;
  --nf-hv-transition-timing: ease;
}

.hv-grow,
.hv-grow-parent {
  --nf-hv-grow-scale: 1.07;
}

.hv-outline,
.hv-outline-parent {
  --nf-hv-outline-width: 0.125rem;
  --nf-hv-outline-offset: 0.3125rem;
  --nf-hv-outline-color: rgba(var(--bs-link-hover-color-rgb), 0);
  --nf-hv-outline-color-active: rgba(var(--bs-link-hover-color-rgb), 1);
}

.hv-outline,
.hv-outline-parent .hv-oc {
  outline: var(--nf-hv-outline-width) solid var(--nf-hv-outline-color);
  outline-offset: 0;
}

.hv-outline:hover,
.hv-outline-parent:hover .hv-oc {
  outline: var(--nf-hv-outline-width) solid;
  outline-color: var(--nf-hv-outline-color-active);
  outline-offset: var(--nf-hv-outline-offset);
}

.hv-outline.hv-outline-inherit,
.hv-outline-parent.hv-outline-inherit {
  --nf-hv-outline-color-active: inherit;
}

/* -- BOXED LAYOUT -- */
/* ---------------------------------------------- */
@media (min-width: max(1024px, 1200px)) {
  .boxed-layout {
    --nf-boxed-layout-bg: rgba(var(--bs-primary-rgb), 0.7);
    --nf-boxed-layout-bg-repeat: no-repeat;
    --nf-boxed-layout-bg-image: none;
    --nf-boxed-layout-bg-size: cover;
    --nf-boxed-layout-bg-attachment: fixed;
    --nf-boxed-layout-padding-y: 2rem;
    --nf-boxed-layout-max-width: 1200px;
    --nf-boxed-layout-border-radius: 0.75rem;
    background-attachment: var(--nf-boxed-layout-bg-attachment);
    background-color: var(--nf-boxed-layout-bg);
    background-image: var(--nf-boxed-layout-bg-image);
    background-repeat: var(--nf-boxed-layout-bg-repeat);
    background-size: var(--nf-boxed-layout-bg-size);
    background-blend-mode: exclusion;
    margin: 0 auto;
    max-width: var(--nf-boxed-layout-max-width);
    padding-block: var(--nf-boxed-layout-padding-y);
    padding-inline-end: 0 !important;
  }

  .boxed-layout:not(.solid-bg):after {
    content: "";
    position: fixed;
    inset: 0;
    background: linear-gradient(180deg, rgba(var(--bs-dark-rgb), 0.79) 29%, rgba(var(--bs-dark-rgb), 0.4) 75%);
    pointer-events: none;
    touch-action: none;
    z-index: -1;
  }

  .boxed-layout .root {
    -webkit-clip-path: inset(0 0 0 0 round var(--nf-boxed-layout-border-radius));
    clip-path: inset(0 0 0 0 round var(--nf-boxed-layout-border-radius));
  }

  .boxed-layout>.mn--reveal.mn--show:before {
    max-width: var(--nf-boxed-layout-max-width);
    margin: 0 auto;
    transition: transform var(--nf-mainnav-reveal-transition-duration) var(--nf-mainnav-reveal-transition-timing) !important;
    transform: translateX(calc(100% - var(--nf-boxed-layout-max-width) + var(--nf-mainnav-max-width)));
  }

  .boxed-layout .mega-dropdown {
    max-width: calc(75 * var(--nf-boxed-layout-max-width) / 100);
  }

  .boxed-layout .sidebar {
    top: var(--nf-boxed-layout-padding-y);
  }

  .boxed-layout .searchbox.searchbox--auto-expand .searchbox__input {
    max-width: calc(var(--nf-boxed-layout-max-width) / 2.2);
  }

  .boxed-layout>.sb--pinned .sidebar {
    top: calc(var(--nf-boxed-layout-padding-y) + var(--nf-header-height) + 0.0625rem);
  }

  .boxed-layout>.hd--sticky .header {
    outline: 1px solid var(--nf-header-bg);
  }
}

/* -- CENTERED LAYOUT -- */
/* ---------------------------------------------- */
@media (min-width: 900px) {
  .centered-layout>.root:not(.mn--max):not(.mn--min)>.mainnav {
    position: absolute;
    inset: 0 auto auto 0;
    height: 100%;
    min-height: 100vh;
  }

  .centered-layout>.root:not(.mn--max):not(.mn--min) .content__header>.content__wrap,
  .centered-layout>.root:not(.mn--max):not(.mn--min) .content__boxed:not(.content__header) {
    width: 900px;
    margin-inline: auto;
  }
}

@media (min-width: 1195px) {
  .centered-layout .sb--pinned .sidebar {
    transition-duration: 0s;
  }

  .centered-layout .sb--pinned:not(.mn--max):not(.mn--min).sb--unite .content__header:after {
    right: var(--nf-sidebar-width);
  }

  .centered-layout .sb--pinned:not(.mn--max):not(.mn--min) .content__header {
    padding: 0;
  }

  .centered-layout .sb--pinned:not(.mn--max):not(.mn--min) .content__boxed:not(.content__header),
  .centered-layout .sb--pinned:not(.mn--max):not(.mn--min) .content__header>.content__wrap {
    padding-right: calc(17.1875rem - (100vw - 900px) / 2);
  }
}

@media (min-width: 1300px) {
  .centered-layout .mainnav {
    position: absolute;
    inset: var(--nf-header-height) auto auto 0;
    height: calc(100% - var(--nf-header-height));
    min-height: 100vh;
  }

  .centered-layout .content__header>.content__wrap,
  .centered-layout .content__boxed:not(.content__header) {
    width: 900px;
    margin-inline: auto;
  }

  .centered-layout .mn--min.tm--expanded-hd .content__header:before {
    left: calc(var(--nf-mainnav-min-width) - var(--nf-sidebar-unite-outer-border-radius));
    transform: none;
    transition: left var(--nf-mainnav-min-transition-duration) var(--nf-mainnav-min-transition-timing);
  }

  .centered-layout .mn--max.tm--expanded-hd .content__header:before {
    left: calc(var(--nf-mainnav-max-width) - var(--nf-sidebar-unite-outer-border-radius));
    transform: none;
    transition: left var(--nf-mainnav-max-transition-duration) var(--nf-mainnav-max-transition-timing);
  }

  .centered-layout .sb--pinned.sb--unite .content__header:after {
    right: var(--nf-sidebar-width);
  }

  .centered-layout .sb--pinned .content__header {
    padding: 0;
  }

  .centered-layout .sb--pinned .content__boxed:not(.content__header),
  .centered-layout .sb--pinned .content__header>.content__wrap {
    padding-right: calc(17.1875rem - (100vw - 900px) / 2);
  }
}

/* -- COLOR COMPONENTS -- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* gray color scheme */
[data-bs-theme=dark][data-scheme=gray] {
  --nf-content-title-ratio: 1.668129681;
  --nf-content-title-color: #697487;
}

/* End gray color scheme */
/* ---------------------------------------------- */
/* navy color scheme */
[data-bs-theme=dark][data-scheme=navy] {
  --nf-content-title-ratio: 1.4020701145;
  --nf-content-title-color: #506999;
}

/* End navy color scheme */
/* ---------------------------------------------- */
/* ocean color scheme */
[data-bs-theme=dark][data-scheme=ocean] {
  --nf-content-title-ratio: 2.0750737159;
  --nf-content-title-color: var(--bs-primary);
}

/* End ocean color scheme */
/* ---------------------------------------------- */
/* lime color scheme */
[data-bs-theme=dark][data-scheme=lime] {
  --nf-content-title-ratio: 3.3518191288;
  --nf-content-title-color: var(--bs-primary);
}

/* End lime color scheme */
/* ---------------------------------------------- */
/* violet color scheme */
[data-bs-theme=dark][data-scheme=violet] {
  --nf-content-title-ratio: 1.5001083291;
  --nf-content-title-color: #895998;
}

/* End violet color scheme */
/* ---------------------------------------------- */
/* orange color scheme */
[data-bs-theme=dark][data-scheme=orange] {
  --nf-content-title-ratio: 4.7836786022;
  --nf-content-title-color: var(--bs-primary);
}

/* End orange color scheme */
/* ---------------------------------------------- */
/* teal color scheme */
[data-bs-theme=dark][data-scheme=teal] {
  --nf-content-title-ratio: 1.8525037737;
  --nf-content-title-color: #337f95;
}

/* End teal color scheme */
/* ---------------------------------------------- */
/* corn color scheme */
[data-scheme=corn] {
  --nf-content-title-color: #b6a20a;
}

[data-bs-theme=dark][data-scheme=corn] {
  --nf-content-title-ratio: 8.1370717413;
  --nf-content-title-color: var(--bs-primary);
}

/* End corn color scheme */
/* ---------------------------------------------- */
/* cherry color scheme */
[data-bs-theme=dark][data-scheme=cherry] {
  --nf-content-title-ratio: 2.9395328057;
  --nf-content-title-color: var(--bs-primary);
}

/* End cherry color scheme */
/* ---------------------------------------------- */
/* coffee color scheme */
[data-bs-theme=dark][data-scheme=coffee] {
  --nf-content-title-ratio: 1.9548759143;
  --nf-content-title-color: #907664;
}

/* End coffee color scheme */
/* ---------------------------------------------- */
/* pear color scheme */
[data-bs-theme=dark][data-scheme=pear] {
  --nf-content-title-ratio: 1.9612044672;
  --nf-content-title-color: #b25c79;
}

/* End pear color scheme */
/* ---------------------------------------------- */
/* night color scheme */
[data-bs-theme=dark][data-scheme=night] {
  --nf-content-title-ratio: 1.1097472633;
  --nf-content-title-color: #505359;
}

/* End night color scheme */

/* ---------------------------------------------- */
/* evblue color scheme */
[data-bs-theme=dark][data-scheme=evblue] {
  --nf-content-title-ratio: 1.4020701145;
  --nf-content-title-color: #304458;
}

/* End evblue color scheme */

/* ---------------------------------------------- */
/* evblue color scheme */
[data-bs-theme=light][data-scheme=evblue] {
  --nf-content-title-ratio: 1.4020701145;
  --nf-content-title-color: #304458;
}

/* End evblue color scheme */
/*# sourceMappingURL=nifty.css.map */